import HeaderTop from './HeaderTop'
import HeaderBottom from './HeaderBottom'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import { useDecision } from '@optimizely/react-sdk'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stickyHeader: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.common.white,
      zIndex: 899, // One less than the language popover
    },
  })
)

const Header = () => {
  const classes = useStyles()

  // Optimizely Test UIPT-499 & WABT-172
  const [decision] = useDecision('wabt-172')

  return (
    <header
      className={clsx({
        [classes.stickyHeader]: decision.enabled,
      })}
    >
      <HeaderTop />
      <HeaderBottom />
    </header>
  )
}

export default Header
