import React from 'react'
import { Link } from '@src/components/Link'

interface LinkWrapperProps {
  externalUrl?: boolean
  linkUrl: string
  linkLabel: string
  className?: string
  closeNav?: () => void
}

const LinkWrapper = (props: LinkWrapperProps) => {
  const { externalUrl = false, linkUrl, linkLabel, className, closeNav } = props
  const extraLinkProps = externalUrl && {
    target: '_blank',
    rel: 'noopener noreferrer nofollow',
  }
  return (
    <Link href={linkUrl} passHref>
      <a
        className={className}
        {...extraLinkProps}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: linkLabel }}
        onClick={() => closeNav && closeNav()}
        onKeyPress={() => closeNav && closeNav()}
        role="link"
        tabIndex={0}
      />
    </Link>
  )
}

export default LinkWrapper
