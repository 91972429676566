import { useEffect } from 'react'
import { sessionStorage } from 'react-storage'
import { useRouter } from '@src/routes'
import { useIntl, IntlShape } from 'react-intl'
import messages from '@utils/messages'
import { useCurrentUser } from '@utils/useCurrentUser'

type Severity = 'success' | 'info' | 'warning' | 'error'

export interface alertBarMessageType {
  open: boolean
  message: string
  severity: Severity
  key?: string
}

enum AlertBarNames {
  INVITE_COWORKER = 'inviteCoworker',
  FULL_REGISTRATION_SUCCESS = 'fullRegistrationSuccess',
  SELF_AFFILIATION_SUCCESS = 'selfAffiliationSuccess',
}

enum AlertBarSessionStorageName {
  SHOW_ALERT_BAR = 'showAlertBar',
  RETURN_URL = 'alertBarReturnUrl',
}

export const initAlertBarMessage: alertBarMessageType = {
  open: false,
  message: '',
  severity: 'success',
  key: '',
}

const alertBarMessages = (intl: IntlShape) => ({
  [AlertBarNames.INVITE_COWORKER]: {
    severity: 'success',
    key: 'INVITE_COWORKER_SUCCEED_MESSAGE',
  },
  [AlertBarNames.FULL_REGISTRATION_SUCCESS]: {
    severity: 'success',
    key: 'FULL_REGISTRATION_SUCCEED_MESSAGE',
    transLationValues: {
      linebreak: <br />,
      feedbackLink: (
        <a
          target="_blank"
          id="registration-survey"
          rel="noopener noreferrer"
          href="https://millipore.pdx1.qualtrics.com/jfe1/preview/previewId/796c4a76-bde7-4c8d-8268-b0d4cae0582d/SV_1EWpkG17HaFAbwq?Q_CHL=preview&Q_SurveyVersionID=current&organizationSSOConfigId=OSC_eXx8Y8lYuCGOPnU&stateID=ffd8a054-91f2-4fa7-a9f7-1473d201f7db&organizationSSOConfigId=OSC_eXx8Y8lYuCGOPnU&stateID=9fcd566f-2df2-4c0a-a169-5ea2a8cabe3e"
        >
          {intl.formatMessage(messages.FEEDBACK)}
        </a>
      ),
    },
  },
  [AlertBarNames.SELF_AFFILIATION_SUCCESS]: {
    severity: 'success',
    key: 'FULL_REGISTRATION_SUCCEED_MESSAGE_AFFILIATION',
    transLationValues: {
      linebreak: <br />,
      feedbackLink: (
        <a
          target="_blank"
          id="registration-survey"
          rel="noopener noreferrer"
          href="https://millipore.pdx1.qualtrics.com/jfe1/preview/previewId/796c4a76-bde7-4c8d-8268-b0d4cae0582d/SV_1EWpkG17HaFAbwq?Q_CHL=preview&Q_SurveyVersionID=current&organizationSSOConfigId=OSC_eXx8Y8lYuCGOPnU&stateID=ffd8a054-91f2-4fa7-a9f7-1473d201f7db&organizationSSOConfigId=OSC_eXx8Y8lYuCGOPnU&stateID=9fcd566f-2df2-4c0a-a169-5ea2a8cabe3e"
        >
          {intl.formatMessage(messages.FEEDBACK)}
        </a>
      ),
    },
  },
})

export const useAlertBarOnRouteChange = (alertBar, setAlertBar) => {
  const router = useRouter()
  const intl = useIntl()
  const { userIsLoggedIn } = useCurrentUser()

  useEffect(() => {
    const showAlertBarMessage: string =
      sessionStorage.getItem(AlertBarSessionStorageName.SHOW_ALERT_BAR) ?? ''
    const returnUrl: string =
      sessionStorage.getItem(AlertBarSessionStorageName.RETURN_URL) ?? ''
    const alertBarMessage =
      showAlertBarMessage && showAlertBarMessage in alertBarMessages(intl)
        ? alertBarMessages(intl)[showAlertBarMessage]
        : ''
    // the bar will be shown from previous page
    if (!alertBar.open && alertBarMessage) {
      setAlertBar({
        open: true,
        message: intl.formatMessage(
          messages[alertBarMessage.key],
          alertBarMessage.transLationValues || {}
        ),
        severity: alertBarMessage.severity,
        key: alertBarMessage.key,
      })
    }

    const homePagePathnames = ['/', '/[country]/[language]']

    const isSamePage = (
      returnUrl: string,
      currentPath: string,
      currentAsPath: string
    ) => {
      const isHomePageMatch = homePagePathnames.includes(returnUrl)

      if (isHomePageMatch) {
        return homePagePathnames.some((path) => path === currentPath)
      } else {
        return currentPath === returnUrl || currentAsPath === returnUrl
      }
    }

    const isSamePageResult = isSamePage(
      returnUrl,
      router.pathname,
      router.asPath
    )

    // the bar will be disappear when navigate to another page
    if ((alertBar.open && !isSamePageResult) || !userIsLoggedIn) {
      setAlertBar(initAlertBarMessage)
      sessionStorage.setItem(AlertBarSessionStorageName.SHOW_ALERT_BAR, '')
    }
  }, [alertBar])
}

export const useGlobalAlertBar = () => {
  const router = useRouter()
  const setAlertMessage = (alertBarKey: string) => {
    sessionStorage.setItem(
      AlertBarSessionStorageName.SHOW_ALERT_BAR,
      alertBarKey
    )
  }
  // call this function to set returnUrl in sessionstorage, to make the alertbar shown in previous page
  const setAlertBarReturnUrl = (url?: string) => {
    sessionStorage.setItem(
      AlertBarSessionStorageName.RETURN_URL,
      url || router.pathname
    )
  }
  const resetAlertBar = (setAlertBar: Function) => {
    setAlertBar(initAlertBarMessage)
    sessionStorage.setItem(AlertBarSessionStorageName.SHOW_ALERT_BAR, '')
  }
  const setInviteCoworkerAlertBar = () =>
    setAlertMessage(AlertBarNames.INVITE_COWORKER)

  const setFullRegistrationSuccessAlertBar = () =>
    setAlertMessage(AlertBarNames.FULL_REGISTRATION_SUCCESS)

  const setSelfAffiliationSuccessAlertBar = () =>
    setAlertMessage(AlertBarNames.SELF_AFFILIATION_SUCCESS)

  return {
    setInviteCoworkerAlertBar,
    setFullRegistrationSuccessAlertBar,
    resetAlertBar,
    setAlertBarReturnUrl,
    setSelfAffiliationSuccessAlertBar,
  }
}
