import Icon, { BaseIconProps } from './Icon'
function MenuIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 20 14"
        {...props}
      >
        <path d="M0 0h20v2.33H0zm0 5.83h20v2.33H0zM0 14h20v-2.33H0z" />
      </svg>
    </Icon>
  )
}
export default MenuIcon
