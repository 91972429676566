import React from 'react'
import LinkWrapper from '@src/aem-content/components/LinkWrapper'
import HandleMarkup from '@src/components/HandleMarkup'

import { SubNavButton } from './TopNavButtons'
import { MainNavItem } from './types'
import styles from './styles'

interface AdditionalLink {
  altText?: string
  image?: string
  url?: string
  title?: string
  openNewTab?: boolean
}

const renditions = {
  xs: 300,
  sm: 640,
  md: 800,
  lg: 1200,
  xl: 1600,
  og: 0,
}

const getTasProxy = (src, size = '') => {
  if (!size) return src

  const isJpg = src.substr(-4) === '.jpg'
  const isPng = src.substr(-4) === '.png'
  const isTas = src.includes('/deepweb/')
  const width = renditions[size] || 0

  if (!width) return src

  if (isTas && (isJpg || isPng)) {
    return src.substr(0, src.length - 4) + '_w' + width + src.substr(-4)
  }

  return src
}

const AdditionalColumn: React.FC<MainNavItem> = (props) => {
  const { additionalColumnHeading, additionalLinks } = props
  const classes = styles()

  return (
    <>
      {additionalColumnHeading && (
        <div className={classes.additionalHeading}>
          <HandleMarkup value={additionalColumnHeading} />
        </div>
      )}
      {additionalLinks &&
        additionalLinks.map((link: AdditionalLink, index: number) => {
          if (link.image) {
            const imageElement = (
              <img
                src={getTasProxy(link.image)}
                alt={link.altText}
                className={`${classes.image} aem-ms-image-cmp--img`}
                data-cmp-hook-image="image"
                title={link.title || ''}
                data-cmp-is="image"
                onLoad={() => {
                  window.dispatchEvent(new Event('resize'))
                }}
              />
            )

            if (link.url) {
              const linkProps = link.openNewTab && {
                target: '_blank',
                rel: 'noopener noreferrer nofollow',
              }
              return (
                <div key={index} className={classes.imageWrapper}>
                  <a
                    className="aem-ms-image-cmp--link"
                    href={link.url}
                    data-cmp-hook-image="link"
                    {...linkProps}
                  >
                    {imageElement}
                  </a>
                  {link.title && (
                    <LinkWrapper
                      linkLabel={link.title}
                      linkUrl={link.url}
                      externalUrl={link.openNewTab}
                      className={classes.imageLink}
                    />
                  )}
                </div>
              )
            } else {
              return (
                <div key={index} className={classes.imageWrapper}>
                  {imageElement}
                </div>
              )
            }
          } else {
            return (
              <SubNavButton
                key={index}
                label={link.title}
                onClick={() => {
                  // Added comment for avoid es-lint error
                }}
                menuItem={false}
                linkProps={{
                  linkUrl: link.url,
                  externalUrl: link.openNewTab,
                }}
              />
            )
          }
        })}
    </>
  )
}

export default AdditionalColumn
