import React from 'react'
import { isPast } from 'date-fns-next'
import { NextRouter } from 'next/router'
import { Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import messages from '@utils/messages'
import { SnackbarMessages } from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { profileRoutes } from '@src/routes'

const showApprovalDelegateExpiredMessage = (currentUser): boolean => {
  if (
    currentUser &&
    currentUser?.__typename === 'LoggedInUser' &&
    currentUser?.approvalConsolidationModel?.activate &&
    currentUser?.approvalConsolidationModel?.delegationApprovalExpirationDate &&
    currentUser?.roles?.isOrderApprover
  ) {
    const delegationEndDate = new Date(
      parseInt(
        currentUser?.approvalConsolidationModel
          ?.delegationApprovalExpirationDate,
        10
      )
    )
    return isPast(delegationEndDate)
  } else {
    return false
  }
}

export const setApprovalDelegateNotification = (
  router: NextRouter,
  setSnackbar,
  setGlobalSnackbarState,
  currentUser
): void => {
  if (
    showApprovalDelegateExpiredMessage(currentUser) &&
    router.pathname === '/[country]/[language]'
  ) {
    const goToApprovalDelegate = (): void => {
      setGlobalSnackbarState({
        open: false,
      })

      router.replace({
        pathname: profileRoutes.orderApprovals.index(),
      })
    }

    const messageBody = (
      <FormattedMessage
        {...messages.APPROVAL_DELEGATE_MSG}
        values={{
          manageLink: (
            <Button onClick={goToApprovalDelegate}>
              <FormattedMessage {...messages.APPROVAL_DELEGATE_MANAGE} />
            </Button>
          ),
        }}
      />
    )
    setSnackbar(SnackbarMessages.DelegateMessageExpired, messageBody)
  }
}
