import React from 'react'
import { Link, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme: Theme) => ({
  skipLink: {
    position: 'absolute',
    top: '-100px',
    left: 0,
    background: theme.palette.primary.main,
    opacity: 0.8,
    color: theme.palette.common.white,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    transition: 'top 0.5s ease',
    zIndex: 1,
    '&:focus': {
      top: 0,
    },
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.primary.dark,
    },
  },
}))

/**
 * SkipLink component
 *
 * This component is used to create a skip link for accessibility purposes.
 * When focused, it allows users to skip to the main content of the page.
 *
 */
const SkipLink = () => {
  const classes = useStyles()

  return (
    <Link
      href={`#main-content`}
      className={classes.skipLink}
      data-testid="skip-link"
    >
      <FormattedMessage id="SKIP_TO_CONTENT" defaultMessage="Skip to Content" />
    </Link>
  )
}

export default SkipLink
