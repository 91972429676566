import React, { useCallback } from 'react'
import getConfig from 'next/config'
import { FormattedMessage } from 'react-intl'
import { MenuItem, Menu, Link as MUILink, Box } from '@material-ui/core'
import messages from '@utils/messages'
import { Link } from '@src/components/Link'
import { UserSession } from '@utils/userSession'
import { UserSessionActions } from '@utils/useUserSession'
import LanguageCustomRadio from './LanguageCustomRadio'
import useStyles from './styles'
import { useCountriesData } from '@src/utils/useCountriesData'
import { sendEvent } from '@src/utils/analytics/coreAnalytics'

const {
  publicRuntimeConfig: { supportedLanguageLabels },
} = getConfig()

interface LanguageSelectMenuProps {
  handleLanguageClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  languageAnchorEl: HTMLSpanElement | null
  setLanguageAnchorEl: (arg: HTMLSpanElement | null) => void
  userSessionActions: UserSessionActions
  userSession: UserSession
}

export const selectedLangParser = (lang) =>
  lang?.toLowerCase() === 'br' ? 'pt' : lang

const handleLanguageOptionClick = ({
  component,
  detail,
  elementText,
  elementType,
  section,
}) => {
  sendEvent({
    payload: {
      event: 'navigation_interaction',
      action: 'header click',
      component: component,
      core_event: 'no',
      detail: detail,
      element_text: elementText,
      element_type: elementType,
      event_group: undefined,
      event_subgroup: undefined,
      link_url: undefined,
      section: section,
      user_detail: undefined,
    },
  })
}

const LanguageSelectMenu: React.FC<LanguageSelectMenuProps> = ({
  handleLanguageClick,
  languageAnchorEl,
  setLanguageAnchorEl,
  userSessionActions,
  userSession,
}) => {
  const { getCountryProp } = useCountriesData()
  const languages: string[] = getCountryProp(
    userSession.country?.toUpperCase(),
    'supportedLanguages'
  )

  const handleLanguageSelect = useCallback(
    (language: string): void => {
      setLanguageAnchorEl(null)
      userSessionActions.setLanguage(language)

      handleLanguageOptionClick({
        component: 'dropdown',
        detail: 'change language',
        elementText: `${supportedLanguageLabels[language.toUpperCase()]}`,
        elementType: 'button',
        section: undefined,
      })

      window.location.reload()
    },
    [userSessionActions, setLanguageAnchorEl]
  )

  const handleLearnMoreClick = () => {
    handleLanguageOptionClick({
      component: 'dropdown',
      detail: 'change language',
      elementText: `learn more`,
      elementType: 'link',
      section: undefined,
    })
  }

  const classes = useStyles()
  return languages ? (
    <Menu
      id="language-menu"
      anchorEl={languageAnchorEl}
      keepMounted
      open={Boolean(languageAnchorEl)}
      onClose={handleLanguageClick}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
      elevation={4}
      className={classes.languageMenu}
      PaperProps={{
        square: true,
      }}
    >
      {' '}
      {languages.map((languageKey) => {
        return (
          <MenuItem
            key={languageKey}
            className={classes.languageMenuItem}
            onClick={() => handleLanguageSelect(languageKey)}
            data-testid={`header-lang-menu-item-${languageKey}`}
          >
            <LanguageCustomRadio
              checked={languageKey === userSession.language}
              id={`header-lang-menu-item-${languageKey}`}
            />{' '}
            {
              supportedLanguageLabels[
                selectedLangParser(languageKey).toUpperCase()
              ]
            }
          </MenuItem>
        )
      })}
      <Box className={classes.learnMoreLink}>
        <Link href="/life-science/ecommerce/language-preference">
          <MUILink onClick={handleLearnMoreClick}>
            <FormattedMessage {...messages.LEARN_MORE} />
          </MUILink>
        </Link>
      </Box>
    </Menu>
  ) : null
}

export default LanguageSelectMenu
