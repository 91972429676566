import React from 'react'
import TopNav from './TopNav'
import { useAemHeaderFooterQuery } from '@src/queries/AemQuery.generated'
import addLevelKeyToNav from '@utils/addLevelKeyToNav'

const WrappedTopNav = () => {
  const { data } = useAemHeaderFooterQuery()

  if (!data?.aemHeaderFooter?.header?.topnav) return null

  return (
    // @ts-ignore * topnav children types & null checks are unhappy
    <TopNav topnav={addLevelKeyToNav(data.aemHeaderFooter.header.topnav)} />
  )
}

export default WrappedTopNav
