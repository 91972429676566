export interface Group {
  items?: Item[]
  level?: number
}

export interface Item {
  name?: string
  childrens?: Item[]
  level?: number
}

/**
 * Adds a 'level' property to each item and its nested children within groups.
 * @param {Group[]} input - The array of groups to process.
 * @returns {Group[]} - The modified array of groups with 'level' properties added to items.
 */
const addLevelKey = (input: Group[] = []): Group[] => {
  // Return empty array if input is not defined or not an array
  if (!input || !Array.isArray(input)) {
    console.error('Invalid input. Expected an array.')
    return []
  }

  /**
   * Recursively processes items to add 'level' property and process nested children.
   * @param {Item[]} items - The array of items to process.
   * @param {number} level - The current level of the items in the hierarchy.
   * @returns {Item[]} - The modified array of items with 'level' properties added.
   */
  const processItems = (items: Item[] = [], level: number): Item[] => {
    return items.map((item) => ({
      ...item,
      level,
      childrens: item.childrens
        ? processItems(item.childrens, level + 1)
        : undefined,
    }))
  }

  return input.map((group) => ({
    ...group,
    level: 0,
    items: processItems(group.items, 1),
  }))
}

export default addLevelKey
