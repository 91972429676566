import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Radio, { RadioProps } from '@material-ui/core/Radio'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
  },
  icon: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '50%',
    width: 18,
    height: 18,
    backgroundColor: 'white',
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    border: `1px solid transparent`,
    backgroundColor: `${theme.palette.primary.main}`,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: `${theme.palette.primary.main}`,
    },
  },
}))

const LanguageCustomRadio = (props: RadioProps) => {
  const classes = useStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

export default LanguageCustomRadio
