import React, { useReducer, useState } from 'react'
import { Box } from '@material-ui/core/'

import { MainNavButton } from './TopNavButtons'
import SubNavModal from './SubNavModal'
import {
  TopNavState,
  TopNavAction,
  MainNavAction,
  SubNavAction,
  MainNavItem,
} from './types'
import { AemTopNav } from '../../aem-types'
import styles from './styles'

const topNavInitialState: TopNavState = {
  L1: null,
  L2: null,
  L3: null,
  anchor: null,
  fullWidth: true,
}

const isMainNavAction = (action: TopNavAction): action is MainNavAction =>
  action.type === 'setL1'
const isSubNavAction = (action: TopNavAction): action is SubNavAction =>
  ['setL2', 'setL3'].includes(action.type)

const topNavReducer = (state: TopNavState, action: TopNavAction) => {
  if (isMainNavAction(action)) {
    return {
      ...topNavInitialState,
      ...action.payload,
    }
  }
  if (isSubNavAction(action)) {
    switch (action.type) {
      case 'setL2':
        return {
          ...topNavInitialState,
          L1: state.L1,
          L2: action.payload,
          fullWidth: state.fullWidth,
        }
      case 'setL3':
        return {
          ...state,
          L3: action.payload,
          fullWidth: state.fullWidth,
        }
      default:
        return state
    }
  }

  if (action.type === 'close') return topNavInitialState
  return state
}

const TopNav: React.FC<{ topnav?: AemTopNav[] }> = (props) => {
  const classes = styles()
  const [navState, dispatch] = useReducer(topNavReducer, topNavInitialState)
  const [leaveDelay, setLeaveDelay] = useState<ReturnType<typeof setTimeout>>()
  const [isOpen, setIsOpen] = useState(false)

  const { topnav } = props
  if (!topnav) return null

  const closeNav = () => {
    dispatch({ type: 'close' })
    setIsOpen(false)
  }
  const setL1 = (
    L1: number,
    anchor: HTMLElement,
    fullWidth: boolean | undefined
  ) =>
    navState.L1 === L1
      ? closeNav()
      : (dispatch({
          type: 'setL1',
          payload: { L1, fullWidth, anchor },
        } as MainNavAction),
        setIsOpen(true))

  const leaveDelayMouseEnter = () => {
    leaveDelay && clearTimeout(leaveDelay)
  }
  const leaveDelayMouseLeave = () => {
    navState.L1 != null &&
      setLeaveDelay(
        setTimeout(() => {
          navState.L1 != null && closeNav()
        }, 250)
      )
  }
  const navProps = { topnav, navState, dispatch, closeNav, isOpen }

  return (
    <Box
      display={{ xs: 'none', sm: 'flex' }}
      width={{ xs: '0', sm: '50%' }}
      className={classes.mainNav}
      onMouseEnter={leaveDelayMouseEnter}
      onMouseLeave={leaveDelayMouseLeave}
    >
      {topnav.map(
        (nav: MainNavItem, index: number) =>
          nav.title && (
            <MainNavButton
              key={index}
              label={nav.title}
              active={navState.L1 === index}
              hoverDelay={100}
              onClick={(event: { currentTarget: HTMLElement }) => {
                setL1(index, event.currentTarget, nav.fullWidthDrawer)
              }}
            />
          )
      )}

      <SubNavModal {...navProps} />
    </Box>
  )
}

export default TopNav
