import React, { useState, useEffect } from 'react'
import { useCookies } from '@src/utils/cookies'
import getConfig from 'next/config'
import {
  Language,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Close,
} from '@material-ui/icons'
import { ButtonBase, Popover, IconButton } from '@material-ui/core'
import { useUserSession } from '@utils/useUserSession'
import { useCurrentUser } from '@utils/useCurrentUser'
import { sendHeaderTopMenuEvents } from '@utils/analytics'
import { EventValues } from '@sial/common-utils'
import CountryRegionSelection from '@src/components/CountryRegionSelection'
import LanguageSelectMenu, { selectedLangParser } from './LanguageSelectMenu'
import useStyles from './styles'

const {
  publicRuntimeConfig: {
    featureFlags: { stratosMultiRegion },
  },
} = getConfig()

const CountryAndLanguageMenu = () => {
  const { userSession, userSessionActions } = useUserSession()
  const [languageAnchorEl, setLanguageAnchorEl] =
    useState<HTMLSpanElement | null>(null)
  const [userLanguage, setUserLanguage] = useState(
    userSession.language?.toUpperCase()
  )
  const [cookies] = useCookies(['_ga'])

  useEffect(() => {
    setUserLanguage(userSession.language.toUpperCase())
  }, [userSession.language])

  const [countryAnchorEl, setCountryAnchorEl] =
    useState<HTMLSpanElement | null>(null)

  const handleCountryClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!countryAnchorEl)
      sendHeaderTopMenuEvents(EventValues.CountryChange, cookies._ga || '')
    !!countryAnchorEl
      ? setCountryAnchorEl(null)
      : setCountryAnchorEl(e.currentTarget)
  }

  const handleLanguageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!languageAnchorEl)
      sendHeaderTopMenuEvents(EventValues.LanguageChange, cookies._ga || '')
    !!languageAnchorEl
      ? setLanguageAnchorEl(null)
      : setLanguageAnchorEl(e.currentTarget)
  }
  const { userIsLoggedIn } = useCurrentUser()
  const LanguageIcon = !!languageAnchorEl ? KeyboardArrowUp : KeyboardArrowDown
  const userCountry = (userSession.country || '').toLocaleUpperCase()
  const classes = useStyles()

  return (
    <>
      {/*Region/Country selection */}
      {/* remove link/muilink when dropdown menu active */}

      {stratosMultiRegion && !userIsLoggedIn ? (
        <ButtonBase
          className={classes.countrySelector}
          onClick={handleCountryClick}
          data-testid="header-country-selector"
          id="header-country-selector"
        >
          <Language className={classes.languageIcon} />
          {userCountry}
        </ButtonBase>
      ) : (
        <span className={classes.countrySelector}>
          <Language className={classes.languageIcon} />
          {userCountry}
        </span>
      )}
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={countryAnchorEl}
        open={Boolean(countryAnchorEl)}
        onClose={handleCountryClick}
        elevation={1}
        marginThreshold={0}
        classes={{ root: classes.popoverRoot }}
        PaperProps={{
          square: true,
          style: {
            width: '100%',
            maxWidth: '100%', //need to overwrite prev max
            padding: '0',
            minHeight: 'fit-content',
          },
        }}
      >
        <IconButton
          onClick={handleCountryClick}
          className={classes.closeIconButton}
        >
          <Close className={classes.closeIcon} />
        </IconButton>
        <CountryRegionSelection mobileDisplay={false} />
      </Popover>

      {/**Language Selection */}
      <ButtonBase
        className={classes.languageSelector}
        onClick={handleLanguageClick}
        data-testid="header-language-selector"
        id="header-language-selector"
      >
        {selectedLangParser(userLanguage)?.toUpperCase()}
        <LanguageIcon className={classes.languageIcon} />
      </ButtonBase>
      <LanguageSelectMenu
        languageAnchorEl={languageAnchorEl}
        setLanguageAnchorEl={setLanguageAnchorEl}
        handleLanguageClick={handleLanguageClick}
        userSessionActions={userSessionActions}
        userSession={userSession}
      />
    </>
  )
}

export default CountryAndLanguageMenu
