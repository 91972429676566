import React, { createRef, useLayoutEffect } from 'react'
import { Modal, Grid, Button } from '@material-ui/core/'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

import AdditionalColumn from './AdditionalColumn'
import { SubNavButton } from './TopNavButtons'
import { TopNavState, SubNavAction, MainNavItem, SubNavItem } from './types'
import styles from './styles'
import { sendMenuNavigationEvent } from '@src/utils/analytics'

interface SubNavColumnProps {
  items: SubNavItem[]
  currentState: number | null
  set: (payload: number) => void
  noBorder?: boolean
  closeNav?: () => void
  handleAnalyticsEvent?: (
    title: string,
    linkUrl: string,
    ga4Only?: boolean
  ) => void
}

const SubNavColumn: React.FC<SubNavColumnProps> = (props) => {
  const {
    items,
    currentState,
    set,
    noBorder = false,
    closeNav,
    handleAnalyticsEvent = () => {
      // Added comment for avoid es-lint error
    },
  } = props
  const classes = styles()

  return (
    <div
      className={clsx(classes.subNavContainer, {
        [classes.noBorder]: noBorder,
      })}
    >
      {items.map((subNavItem: SubNavItem, index: number) => {
        return (
          <SubNavButton
            key={index}
            label={subNavItem.title}
            hoverDelay={300}
            active={currentState === index}
            onClick={() => {
              subNavItem.menuItem && set(index)
            }}
            // Set state when the item is not a menuItem (with children)
            setState={() => {
              !subNavItem.menuItem && set(index)
            }}
            menuItem={subNavItem.menuItem}
            linkProps={{
              linkUrl: subNavItem.url,
              externalUrl: subNavItem.openNewTab,
            }}
            level={subNavItem.level}
            handleAnalyticsEvent={(ga4Only) =>
              handleAnalyticsEvent(
                subNavItem?.title || '',
                subNavItem.url || '',
                ga4Only
              )
            }
            closeNav={closeNav}
          />
        )
      })}
    </div>
  )
}

interface SubNavModalProps {
  topnav: MainNavItem[]
  navState: TopNavState
  dispatch: React.Dispatch<SubNavAction>
  closeNav: () => void
  isOpen: boolean
}

const SubNavModal: React.FC<SubNavModalProps> = (props) => {
  const { navState, topnav, dispatch, closeNav, isOpen } = props
  const setL2 = (payload: number) => dispatch({ type: 'setL2', payload })
  const setL3 = (payload: number) => dispatch({ type: 'setL3', payload })
  const classes = styles()
  const modalRef = createRef<HTMLElement>()

  if (typeof window !== 'undefined') {
    // should be conditional, server complains during SSR.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      if (modalRef.current && navState.anchor) {
        const anchorRect = navState.anchor.getBoundingClientRect()
        modalRef.current.style.top = `${anchorRect.height}px`
        modalRef.current.style.bottom = 'auto'
        modalRef.current.style.left = navState.fullWidth
          ? '0px'
          : `${anchorRect.left}px`
        modalRef.current.style.right = navState.fullWidth ? '0px' : 'auto'
        modalRef.current.style.zIndex = '100'
        modalRef.current.style.position = 'absolute'
      }
    }, [modalRef, navState])
  }

  const L1item = topnav[navState.L1 || 0] // set to 0 by default allows us to ensure we're rending hidden menus on page load
  const L2items = L1item?.items || []
  const L3items =
    navState?.L2 !== null ? L2items[navState?.L2]?.childrens || [] : []
  const L4items =
    navState?.L3 !== null ? L3items[navState?.L3]?.childrens || [] : []

  return (
    <Modal
      ref={modalRef}
      open={isOpen}
      hideBackdrop
      disablePortal
      disableEnforceFocus
      disableScrollLock
      className={clsx(classes.mainModal, {
        [classes.smallMenu]: !navState.fullWidth,
      })}
      keepMounted
    >
      {navState.fullWidth ? (
        <div className={classes.fullWidthWrapper}>
          <Grid container>
            {topnav.map((nav, index) => (
              <Grid
                item
                xs={3}
                key={index}
                className={
                  index === navState?.L1 ? classes.isVisible : classes.isHidden
                }
              >
                <SubNavColumn
                  items={nav?.items || []}
                  set={setL2}
                  currentState={navState.L2}
                  handleAnalyticsEvent={(title, linkUrl, ga4Only) =>
                    sendMenuNavigationEvent({
                      L1item: L1item?.title,
                      L2item: title,
                      linkUrl,
                      ga4Only,
                    })
                  }
                  closeNav={closeNav}
                />
              </Grid>
            ))}

            <Grid item xs={3}>
              {L3items.length > 0 && (
                <SubNavColumn
                  items={L3items}
                  set={setL3}
                  currentState={navState.L3}
                  handleAnalyticsEvent={(title, linkUrl, ga4Only) =>
                    sendMenuNavigationEvent({
                      L1item: L1item?.title,
                      L2item: L2items[navState?.L2 || 0]?.title,
                      L3item: title,
                      linkUrl,
                      ga4Only,
                    })
                  }
                  closeNav={closeNav}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              {L4items.length > 0 && (
                <SubNavColumn
                  items={L4items}
                  set={() => {
                    // Added comment for avoid es-lint error
                  }}
                  currentState={null}
                  noBorder
                  handleAnalyticsEvent={(title, linkUrl, ga4Only) =>
                    sendMenuNavigationEvent({
                      L1item: L1item?.title,
                      L2item: L2items[navState.L2 || 0]?.title,
                      L3item: L3items[navState.L3 || 0]?.title,
                      L4item: title,
                      linkUrl,
                      ga4Only,
                    })
                  }
                  closeNav={closeNav}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <div className={clsx(classes.subNavContainer, classes.noBorder)}>
                <Button onClick={closeNav} className={classes.closeIcon}>
                  <CloseIcon className={classes.closeIconSVG} />
                </Button>
                {L1item && <AdditionalColumn {...L1item} />}
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.smallWrapper}>
          <SubNavColumn
            items={L2items}
            set={setL2}
            currentState={navState.L2}
            noBorder
            closeNav={closeNav}
          />
        </div>
      )}
    </Modal>
  )
}

export default SubNavModal
